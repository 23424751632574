import React from "react";
import cn from "classnames";
import Row from "./layouts/Row";
import styled from "styled-components";
import Container from "./layouts/Container";
import {media} from "./styles/MediaMixins";

const WhyCypressContainer = styled(Container)`
    background: linear-gradient(315deg, #20BB87 0%, #11998E 100%);
`

const Title = styled.h2`
    color: #FFFFFF;
    ${media.touch`
        font-size: ${40 / 16}rem;
        line-height: ${45 / 16}rem;
        text-align: left;
    `};
    ${media.desktop`
        font-size: ${60 / 16}rem;
        line-height: ${62 / 16}rem;
        text-align: center;
    `};
    font-weight: bold;
`

const Years = styled.p`
    color: #FFFFFF;
    ${media.touch`
        font-size: ${90 / 16}rem;
        line-height: ${105 / 16}rem;
    `};
    ${media.desktop`
        font-size: ${110 / 16}rem;
        line-height: ${134 / 16}rem;
    `};
    font-weight: bold;
`

const Experience = styled.p`
    color: #FFFFFF;
    ${media.touch`
        font-size: 2.8125rem;
        line-height: 3.875rem;
    `};
    ${media.desktop`
        font-size: 2rem;
        line-height: 2.8125rem;
    `};
    font-weight: bold;
`

const Paragraph = styled.p`
    margin-top: 0.9375rem;
    ${media.desktop`
        font-size: 1.125rem;
        line-height: 2rem;
        text-align: center;
    `};
    ${media.touch`
        font-size: 0.875rem;
        line-height: 1.125rem;
        text-align: left;
    `};
    color: #FFFFFF66;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3.3125rem;
`

const WhyRowItem = styled.div`
    ${media.touch`
        width: 100%;
        text-align: left;
        ${props => props.marginTop && (`margin-top: ${props.marginTop / 16}rem`)};
    `};
    min-width: 0;
    ${props => props.maxWidth || (`max-width: ${props.maxWidth / 16}rem`)};
`

export default () => {
    return (
        <WhyCypressContainer className={cn('has-padding-top-80 has-padding-bottom-70')}>
            <WhyRowItem>
                <Title>
                    Why Cypress
                </Title>
            </WhyRowItem>
            <WhyRowItem>
                <Paragraph>
                    You care how much we know when you know how much we care
                </Paragraph>
            </WhyRowItem>
            <Row maxWidth={1420}>
                <WhyRowItem maxWidth={240}>
                    <Years>8</Years>
                    <Experience>Years</Experience>
                    <Experience>Experience</Experience>
                </WhyRowItem>
                <WhyRowItem maxWidth={240} marginTop={70}>
                    <Years>33+</Years>
                    <Experience>Successful</Experience>
                    <Experience>Projects</Experience>
                </WhyRowItem>
                <WhyRowItem maxWidth={240} marginTop={70}>
                    <Years>14</Years>
                    <Experience>Top</Experience>
                    <Experience>Tech Pros</Experience>
                </WhyRowItem>
                <WhyRowItem maxWidth={240} marginTop={70}>
                    <Years>96%</Years>
                    <Experience>Satisfaction</Experience>
                    <Experience>Rate</Experience>
                </WhyRowItem>
            </Row>
        </WhyCypressContainer>
    )
}