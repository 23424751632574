import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import "./portfolio_preview.sass";
import {Link} from "gatsby";
import Title from "../title/Title";
import styled from "styled-components";
import {fluidSizing, fluidTypography, media} from "../styles/MediaMixins";
import Paragraph from "../paragraph/Paragraph";

const SeeAll = styled(Link)`
    color: #20BB87;
    text-align: right;
    ${fluidTypography(14, 19)};
    ${fluidSizing('line-height', 14, 19)};
    font-weight: bold;
    ${media.touch`
        display: none;
    `}
`

const SeeAllOnDesktop = styled(Link)`
    color: #20BB87;
    text-align: right;
    ${fluidTypography(14, 19)};
    ${fluidSizing('line-height', 14, 19)};
    font-weight: bold;
    margin-top: ${11 / 16}rem;
    ${media.desktop`
        display: none;
    `}
`

const VewCase = styled(Link)`
    text-align: center;
    font-weight: 700;
    margin-top: ${10 / 16}rem;
    border-radius: ${27.5 / 16}rem;
    ${media.desktop`
        &:hover {
            background: #FFFFFF none;
            color: #11998E;
            border: 2px solid #20bb87;
        }
    `};

    border: 2px solid transparent;
    background-clip: padding-box !important;
    background: linear-gradient(315deg, #20BB87 0%, #11998E 100%);
    color: #FFFFFF;
    
    @media (max-width: 768px) {
        &:active {
            background: #FFFFFF none;
            color: #11998E;
            border: 2px solid #20bb87;
        }
        &:hover:not(:active) {
            color: #FFFFFF;
        }
        font-size: 0.5rem;
        padding-left: ${27 / 16}rem;
        padding-right: ${27 / 16}rem;
        padding-top: ${9 / 16}rem;
        padding-bottom: ${9 / 16}rem;
        line-height: ${10 / 16}rem;
    }
    @media (min-width: 1409px) {
        font-size: 1rem;
        padding-left: ${55 / 16}rem;
        padding-right: ${55 / 16}rem;
        padding-top: ${17 / 16}rem;
        padding-bottom: ${17 / 16}rem;
        line-height: ${19 / 16}rem;
    }
    @media (min-width: 769px) and (max-width: 1408px){
        font-size: calc(0.5rem + 8 * (100vw - 768px) / 640);
        padding-left: calc(${27 / 16}rem + (${55 - 27}) * (100vw - 768px) / 640);
        padding-right: calc(${27 / 16}rem + (${55 - 27}) * (100vw - 768px) / 640);
        padding-top: calc(${9 / 16}rem + (${17 - 9}) * (100vw - 768px) / 640);
        padding-bottom: calc(${9 / 16}rem + (${17 - 9}) * (100vw - 768px) / 640);
        line-height: calc(${10 / 19}rem + (${19 - 10}) * (100vw - 768px) / 640);
    }
    white-space: nowrap;
`

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`

const PreviewRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-start;
    width: 100%;
    margin: 0 auto;
    ${media.touch`
        justify-content: space-around;
        flex-direction: column;
        flex-wrap: wrap;
    `};
    ${media.desktop`
        max-width: 98.125rem;
    `};
`

const ItemRow = styled(PreviewRow)`
  ${media.desktop`max-width: 99.375rem`};
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
`

const RowItem = styled.div`
  width: 100%;
  max-width: 40.625rem;
`

const TitleContainer = styled(PreviewContainer)`
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    margin-top: 2.5rem;
    margin-bottom: 0.6875rem;
    ${media.desktop`
        margin-top: 4.375rem;
        margin-bottom: 1rem;
    `}
`

const PortfolioPreview = ({title, description, showAllLink, portfolios}) => {
  return (
    <>
      {title &&
      <TitleContainer>
        <PreviewRow>
          <Title>{title}</Title>
          {showAllLink &&
          <SeeAll to={'/portfolio/'}>
            SEE ALL &#8594;
          </SeeAll>}

        </PreviewRow>
        <PreviewRow>
          <Paragraph>
            <br/>
            {description}
          </Paragraph>
        </PreviewRow>
        {showAllLink &&
        <PreviewRow>
          <SeeAllOnDesktop to={'/portfolio/'}>
            SEE ALL &#8594;
          </SeeAllOnDesktop>
        </PreviewRow>}
      </TitleContainer>}


      <PreviewContainer>
        <ItemRow>
          {portfolios.map((portfolio, index) =>
            <RowItem key={index} className={'has-padding-10 has-margin-top-30-touch'}>
              <div className={'portfolio-logo-container'}>
                <Img style={{position: 'absolute'}}
                     alt={`Portfolio item ${portfolio.title}`}
                     className={'portfolio-logo'}
                     fluid={portfolio.image.childImageSharp.fluid}/>
                <div className={'portfolio-logo-tags'}>
                  {portfolio.platforms.join('\u00A0\u00A0\u00A0\u00A0')}
                </div>
              </div>
              <h2 className={'cp-port-title has-padding-top-30-desktop has-padding-top-10-touch'}>
                {portfolio.title}
              </h2>
              <div className={'cp-port-content has-padding-bottom-10'}>
                {portfolio.subtitle}
              </div>
              <VewCase to={portfolio.link}>
                VIEW CASE
              </VewCase>
            </RowItem>
          )}
        </ItemRow>
      </PreviewContainer>
    </>
  )
};

PortfolioPreview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showAllLink: PropTypes.bool,
  portfolios: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object.isRequired,
      platforms: PropTypes.array.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  )
};

export default PortfolioPreview;