import React from 'react';
import PropTypes from 'prop-types';
import {graphql, Link} from 'gatsby';

import Layout from '../components/Layout';
import ServiceSteps from "../components/services/ServiceStep";
import BackgroundImage from 'gatsby-background-image';

import DiscussIdeas from "../components/DiscussIdeas";
import PortfolioPreview from "../components/portfolio/PortfolioPreview";
import Paragraph from "../components/paragraph/Paragraph";
import WorkWithUsSlider from "../components/who_work_with_us/WorkWithUsSlider";
import TheyClapUsSlider from "../components/they_clap_us/TheyClapUsSlider";
import FooterDivider from "../components/FooterDivider";
import Title from "../components/title/Title";
import Container from "../components/layouts/Container";
import Row from "../components/layouts/Row";
import WhyCypress from "../components/WhyCypress";
import styled from "styled-components";
import GenericHeader from "../components/header_section/GenericHeader";
import {fluidSizing, fluidTypography, media} from "../components/styles/MediaMixins";

const TitleHighlight = styled.span`
    color: #38EF7D
`

const IndexTitle = styled(Title)`
  text-align: left;
  margin-top: ${29 / 16}rem;
  margin-bottom: ${10 / 16}rem;
  @media (min-width: 1024px) {
    margin-top: ${93 / 16}rem;
    margin-bottom: ${15 / 16}rem;
  }
`

const IndexParagraph = styled(Paragraph)`
    ${media.touch`
        margin-bottom: ${34 / 16}rem;
    `};
    ${media.desktop`
        margin-bottom: ${50 / 16}rem;
    `};
`

const ContactUs = styled(Link)`
    border: 2px solid transparent;
    background-clip: padding-box;
    text-align: center;
    font-weight: 700;
    margin-top: ${10 / 16}rem;

    border-radius: ${27.5 / 16}rem;
    ${media.desktop`
        &:hover {
            border: 2px solid white;
            background: transparent;
            color: #FFFFFF;
        }
    `};
    ${media.touch`
        &:active {
            border: 2px solid white;
            background: linear-gradient(315deg, #20BB87 0%, #11998E 100%);
            color: #FFFFFF;
        }
        &:hover:not(:active) {
            color: #11998E;
        }
    `};
   
    
    background: #FFFFFF;
    color: #11998E;

    ${fluidSizing('padding-left', 27, 55)};
    ${fluidSizing('padding-right', 27, 55)};
    ${fluidSizing('padding-top', 9, 15)};
    ${fluidSizing('padding-bottom', 9, 15)};

    white-space: nowrap;

    ${fluidTypography(8, 16)};
    ${fluidSizing('line-height', 10, 19)};
`

const NoBreak = styled.span`
    @media (min-width: 420px) {
        white-space: nowrap;
    }
`

export const IndexPageTemplate = ({title_image, title_image_mobile, image_step_background, steps, projects}) => {
  const title =
    <>
      <NoBreak>We <TitleHighlight>Create</TitleHighlight> Mobile</NoBreak><br/>
      <NoBreak> <TitleHighlight>Apps</TitleHighlight> That</NoBreak><br/>
      <NoBreak>Deserve Claps</NoBreak>
    </>;

  return (
    <>
      <GenericHeader title={title}
                     subtitle={`Cypress develops IT projects from any stage to your satisfaction. We work today 
                           for your future success. We put our heart and soul into offering digital solutions that make 
                           an impact on our customers. In Cypress, we believe in accuracy from start to finish. 
                           In the end, you get high quality at a competitive budget`}
                     title_image={title_image}
                     title_image_mobile={title_image_mobile}
                     no_right_padding={true}
                     action={<ContactUs to={'/contact/'}>GET IN TOUCH</ContactUs>}/>
      <BackgroundImage Tag={"div"} fluid={image_step_background}>
        <Container>
          <Row>
            <IndexTitle>
              What You Came For
            </IndexTitle>
          </Row>
          <Row>
            <IndexParagraph>
              Our applications are designed with a simple philosophy in mind - we believe that
              information, in the right hands, has the power to change.
              <br/>
              We specialize in bespoke software
              development that puts the user first and delivers life-enhancing digital experiences.
            </IndexParagraph>
          </Row>
        </Container>
        <ServiceSteps steps={steps}/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </BackgroundImage>

      <WhyCypress/>

      <PortfolioPreview title={'Portfolio'}
                        description={`Our Projects`}
                        showAllLink={true}
                        portfolios={[
                          {
                            image: projects[0].image,
                            platforms: projects[0].platforms,
                            title: projects[0].title,
                            subtitle: projects[0].subtitle,
                            link: `/portfolio/${projects[0].path}/`
                          },
                          {
                            image: projects[1].image,
                            platforms: projects[1].platforms,
                            title: projects[1].title,
                            subtitle: projects[1].subtitle,
                            link: `/portfolio/${projects[1].path}/`
                          }
                        ]}/>
      <br/>
      <br/>
      <br/>
      <TheyClapUsSlider/>
      <br/>
      <WorkWithUsSlider/>
      <br/>
      <br/>
      <br/>
      <br/>
      <DiscussIdeas/>
      <FooterDivider marginTop={70} marginBottom={50}/>
      <br/>
    </>
  )
};

IndexPageTemplate.propTypes = {
  subtitle: PropTypes.string,
  title_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const IndexPage = ({data}) => {
  const {frontmatter} = data.markdownRemark;

  let projects = data.allMarkdownRemark.edges.map(remark => ({
    image: remark.node.frontmatter.preview_image_square,
    title: remark.node.frontmatter.title,
    subtitle: remark.node.frontmatter.subtitle,
    path: remark.node.frontmatter.path,
    platforms: remark.node.frontmatter.platforms
  }));
  projects = projects.sort(() => 0.5 - Math.random()).slice(0, 2);
  return (
    <Layout local_title={'Cypress'}
            meta_image={frontmatter.title_image.childImageSharp.fluid.src}
            meta_image_width={frontmatter.title_image.childImageSharp.fluid.presentationWidth}
            meta_image_height={frontmatter.title_image.childImageSharp.fluid.presentationHeight}
            meta_keyword={`native mobile app, mobile app, mobile software development, app development companies, web development, cms development`}
            meta_description={`Promote your business through a native mobile app. Reach a wide range of audiences and lead your competition. Stay tuned and let your customers go mobile.`}>
      <IndexPageTemplate
        title_image={frontmatter.title_image}
        title_image_mobile={frontmatter.title_image_mobile}
        projects={projects}
        image_step_background={frontmatter.image_step_background.childImageSharp.fluid}
        steps={frontmatter.steps}
      />
    </Layout>
  )
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage

export const indexPageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        title_image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1066, srcSetBreakpoints: [720, 810, 835, 1066, 2132], sizes: "(max-width: 1216px) calc(720px), (min-width: 1217px) and (max-width: 1366px) calc(810px), (min-width: 1367px) and (max-width: 1408px) calc(835px), (min-width: 1409px) calc(916px), 100vw") {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title_image_mobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 906, srcSetBreakpoints: [768, 1006, 1024]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image_step_background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920, srcSetBreakpoints: [768, 1920], sizes: "(min-width: 1280px) 100vw, (min-width: 768px) 100vw, 100vw") {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        steps {
          title
          subtitle
          href
          image {
            publicURL
          }
        }
      }
    }

    allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___path]}, filter: {frontmatter: {templateKey: {eq: "portfolio-case-page"}}}) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            platforms
            path
            preview_image_square {
              childImageSharp {
                fluid(quality: 100, maxWidth: 252, srcSetBreakpoints: [252]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
